<template>
  <div class="content-item">
    <div class="item-header">数据表现</div>

    <div class="item-buttons">
      <div
        :class="['button', { 'is-active': item.data_type == currentTrendType }]"
        v-for="item in dataTrendOptions"
        :key="item.id"
        @click="handleChangeTrendDataType(item.data_type)"
      >
        <div class="button-label">{{ item.label }}</div>
        <div class="button-value">{{ item.value }}</div>
      </div>
    </div>

    <div class="item-chart">
      <div class="chart-content">
        <div class="header">
          <div class="header-type">
            <span>{{ getTrendLabel }}趋势</span>
            <el-select class="autoWidth" v-model="filterParams.type" placeholder="请选择" @change="handleChangeType">
              <template slot="prefix">
                {{ typeList.find((item) => item.value == filterParams.type).label }}
              </template>
              <el-option v-for="type in typeList" :key="type.value" :label="type.label" :value="type.value"> </el-option>
            </el-select>
          </div>
          <div class="header-date">
            <el-date-picker
              v-model="filterParams.filterTime"
              :clearable="false"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>

          <div class="header-export" @click="exportData">
            <i class="el-icon-download export-icon"></i>
            <span>导出数据</span>
          </div>
        </div>

        <div class="chart" ref="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentItem", // 组件名称
  data() {
    return {
      itemData: {},
      currentTrendType: "1",
      dataTrendOptions: [
        {
          id: "play_count",
          label: "播放量",
          data_type: "1",
          value: 0,
        },
        {
          id: "digg_count",
          label: "点赞量",
          data_type: "2",
          value: 0,
        },
        {
          id: "comment_count",
          label: "评论量",
          data_type: "3",
          value: 0,
        },
        {
          id: "share_count",
          label: "分享量",
          data_type: "4",
          value: 0,
        },
      ],
      typeList: [
        {
          label: "新增",
          value: "1",
        },
        {
          label: "累计",
          value: "2",
        },
      ],
      filterParams: {
        filterTime: "",
        type: "2", // 类型：1=新增 2=累计
      },
      flag: true,
    };
  },
  computed: {
    getTrendLabel() {
      const dataTrend = this.dataTrendOptions.find((item) => item.data_type == this.currentTrendType);
      return dataTrend.label || "";
    },
  },
  props: {
    id: { type: String | Number, Required: true },
    date: { type: String, Required: true },
    trends: { type: Object, Required: true },
  },
  mounted() {
    this.initDefaultTime();
    this.getAnalysisTrend();
  },
  watch: {
    "filterParams.filterTime": {
      handler(newVal) {
        this.getAnalysisTrend();
      },
      deep: true,
      immediate: false,
    },
    id: {
      handler(newVal) {
        this.getAnalysisTrend();
      },
      deep: true,
      immediate: false,
    },
    trends: {
      handler(newVal) {
        this.dataTrendOptions.forEach((item) => {
          this.$set(item, "value", this.trends[item.id]);
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    // 初始化日期，默认最近三十天
    initDefaultTime() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setDate(yesterday.getDate() - 30);
      this.filterParams.filterTime = [this.formatDate(oneMonthAgo), this.formatDate(yesterday)];
    },

    // 数据表现
    getAnalysisTrend() {
      // 数据属性
      if (this.flag) {
        this.flag = false;
        const params = {
          id: this.id,
          date: this.filterParams.filterTime.toString(),
          data_type: this.currentTrendType,
          // date_type: 1,
          type: this.filterParams.type,
        };

        this.$httpStudent.axiosGetBy(this.$api.dataCenterItemAnalysisTrend, params, (res) => {
          if (res.code === 200) {
            this.itemData = res.data;
            this.$nextTick(() => this.setChartOptions());
            this.flag = true;
          } else {
            this.$message({
              type: "error",
              message: res.message,
              duration: 1000,
            });
          }
        });
      }
    },

    setChartOptions() {
      if (!this.$refs.chart) return;

      const data = this.itemData;
      const chart = this.$echarts.init(this.$refs.chart);
      const options = {
        grid: {
          left: "0%",
          right: "1%",
          top: "10%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis", // 悬停时触发
          axisPointer: { type: "shadow" }, // 指示器类型为阴影
          formatter: (params) => {
            return `
              <div style="color: '#86909C';">${params[0].name}</div>
              <div style="color: #000;">${this.getTrendLabel}: <span style="color: ${params[0].color}; font-size: 20px;">${params[0].value}</span></div>
            `;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.date),
          axisLabel: {
            color: "#86909C",
            formatter: function (value) {
              // 返回字符串的最后五个字符
              return value.length > 5 ? value.slice(-5) : value;
            },
          },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "86909C", formatter: "{value}" },
          axisLine: { show: false, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: this.getTrendLabel,
            type: "line",
            showSymbol: false,
            smooth: true,
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
            },
          },
        ],
      };
      chart.clear();
      chart.setOption(options);

      window.addEventListener("resize", () => chart.resize());
    },

    handleChangeTrendDataType(type) {
      this.currentTrendType = type;
      this.getAnalysisTrend();
    },

    handleChangeType() {
      this.getAnalysisTrend();
    },

    exportData() {
      let params = {
        id: this.id,
        date: this.filterParams.filterTime.toString(),
        type: this.filterParams.type,
        data_type: this.currentTrendType,
        token: localStorage.getItem("studentToken"),
      };
      const typeLabel = this.getTrendLabel;

      this.$httpStudent.axiosSetTypeGet(this.$api.dataCenterItemAnalysisTrendExport, params, "arraybuffer", (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${typeLabel}数据表现数据.xlsx`; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-item {
  .item-header {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-style: normal;
  }

  .item-buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 15px;

    .button {
      width: 215px;
      height: 80px;
      background-color: #f7f9fb;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 16px 24px;
      cursor: pointer;

      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #777e87;
      line-height: 14px;
      text-align: left;
      font-style: normal;

      .button-value {
        font-size: 16px;
        color: #111111;
        line-height: 19px;
        margin-top: 3px;
      }

      &.is-active {
        background-color: #594fee;

        & > .button-label,
        & > .button-value {
          color: #fff;
        }
      }
    }
  }

  .item-chart {
    width: 100%;
    min-height: calc(100vh - 570px);
    box-sizing: border-box;
    background-color: #fff;
    padding-top: 24px;

    .chart-content {
      height: 320px;
      background-color: #f7f9fb;
      border-radius: 8px;
      padding: 24px;
      .header {
        display: flex;
        align-items: center;
        position: relative;

        &-type {
          margin-right: 10px;
          span {
            margin-right: 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: left;
            font-style: normal;
          }

          ::v-deep .el-input__prefix {
            position: relative;
            left: 0;
            box-sizing: border-box;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            cursor: pointer;
          }

          ::v-deep .el-input__suffix {
            margin-left: 3px;
            position: relative !important;
          }

          ::v-deep .el-input__inner {
            width: max-content;
            width: 0;
            height: 25px;
            border: none;
            padding: 0;
          }

          ::v-deep .el-input__icon {
            line-height: 25px;
          }
        }

        &-date {
          ::v-deep .el-range-editor.el-input__inner {
            background-color: #f2f2f4;
          }
          ::v-deep .el-date-editor .el-range-input {
            background-color: transparent;
          }

          ::v-deep .el-date-editor--daterange.el-input,
          .el-date-editor--daterange.el-input__inner,
          .el-date-editor--timerange.el-input,
          .el-date-editor--timerange.el-input__inner {
            width: 250px;
          }
        }

        &-export {
          position: absolute;
          right: 0px;
          top: 0px;
          font-family: PingFang SC, PingFang SC;
          color: #594fee;
          font-size: 12px;
          font-weight: 400;
          // line-height: 14px;
          cursor: pointer;

          .export-icon {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }
    }

    .chart {
      height: 280px;
      margin-top: 10px;
      // border: 1px solid black;
    }
  }
}
</style>