<template>
  <div class="content-analysis">
    <div class="header-box">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item v-for="item in menuConfig" :key="item.index" :index="item.index">{{ item.name }}</el-menu-item>
      </el-menu>
    </div>

    <div class="content-card-box">
      <div class="card">
        <div class="card_cover-img" @click="toPlayVideo(contentData.video_url)">
          <img :src="contentData.cover_url" alt="" />
          <i class="el-icon-caret-right img-play-icon"></i>
        </div>

        <div class="card_info">
          <div class="info-title ellipses">{{ contentData.title }}</div>
          <div class="info-time">{{ contentData.video_create_time }}</div>
          <div class="info-status">审核状态：{{ contentData.status_name }}</div>
        </div>

        <div class="card_more" @click="selectMore">
          <i class="el-icon-menu select-icon"></i>
          <span>选择作品</span>
        </div>
      </div>
    </div>

    <!-- component -->
    <component :is="getComponents" :id="$route.query.id" :date="$route.query.date" :trends="dataTrend"></component>

    <!-- 作品列表 -->
    <el-drawer :visible.sync="drawer" size="448px" :show-close="false">
      <div class="drawer-title" slot="title">
        <div class="drawer-title_label">作品列表</div>
        <div class="drawer-title_count">共{{ contentTotal }}个视频</div>
      </div>

      <div class="drawer-content">
        <div class="drawer-content-item" v-for="(item, index) in contentList" :key="index" @click="selectContent(item.id)">
          <img class="item-img" :src="item.cover_url" alt="" />
          <div class="item-info">
            <div class="item-info_title">{{ item.title }}</div>
            <div class="item-info_time">发布时间：{{ item.video_create_time }}</div>
          </div>
        </div>

        <div class="medio-load">
          <span v-if="!loadMore" style="cursor: auto">没有更多作品</span>
          <span v-else @click="loadMoreData">点击加载更多</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ContentItem from "@/components/dataCenter/ContentItem.vue";
import CommentWords from "@/components/dataCenter/CommentWords.vue";
export default {
  data() {
    return {
      activeIndex: "1",
      menuConfig: [
        {
          index: "1",
          name: "作品分析",
          component: "ContentItem",
        },
        {
          index: "2",
          name: "评论热词",
          component: "CommentWords",
        },
      ],
      contentData: {},
      params: {
        // 作品列表请求参数
        id: "",
        date: "",
        paging: "1",
        page: 1,
        limit: 50,
      },
      dataTrend: {
        play_count: 0,
        digg_count: 0,
        comment_count: 0,
        share_count: 0,
      },
      initContentListFlag: true, // 点击选择作品是否重新进行请求
      contentList: [],
      contentTotal: 0,
      drawer: false,
      loadMore: false,
    };
  },

  mounted() {
    this.getContentDetail(this.$route.query.id);
  },

  computed: {
    getComponents() {
      const component = this.menuConfig.find((item) => item.index == this.activeIndex);
      return component.component;
    },
  },

  components: {
    ContentItem,
    CommentWords,
  },

  methods: {
    getContentDetail(id) {
      if (!id) return;

      const params = {
        id: id,
      };

      this.$httpStudent.axiosGetBy(this.$api.dataCenterAnalysisSummary, params, (res) => {
        if (res.code == 200) {
          this.contentData = res.data;
          for (let key in this.dataTrend) {
            this.dataTrend[key] = this.contentData[key];
          }
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 判断是否可以编辑封面，超过30天不可编辑
    defaultDate() {
      const date = new Date();
      const prevDate = new Date();
      prevDate.setFullYear(prevDate.getFullYear() - 1);
      const dates = [this.formatDate(prevDate), this.formatDate(date)].toString();

      return dates;
    },
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    selectContent(id) {
      this.drawer = false;

      const date = this.$route.query.date ? this.$route.query.date : this.defaultDate()
      this.$router.replace({ query: { id, accountIds: this.$route.query.accountIds, date: date } });
      this.getContentDetail(id);
    },

    getContentList(isAppend = false) {
      const date = this.$route.query.date ? this.$route.query.date : this.defaultDate()

      this.params.id = this.$route.query.accountIds;
      this.params.date = date;
      this.params.page = isAppend ? this.params.page + 1 : 1;

      this.$httpStudent.axiosGetBy(this.$api.dataCenterContentList, this.params, (res) => {
        if (res.code == 200) {
          // todo
          this.contentList = isAppend ? this.contentList.concat(res.data.list) : res.data.list;
          this.contentTotal = res.data.total;

          this.loadMore = res.data.total > this.contentList.length ? true : false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    toPlayVideo(url) {
      window.open(url);
    },

    selectMore() {
      // todo
      if (this.initContentListFlag) {
        this.getContentList();
        this.initContentListFlag = false;
      }

      this.drawer = true;
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },

    loadMoreData() {
      this.getContentList(true);
    },
  },
};
</script>

<style scoped lang="scss">
.ellipses {
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出容器部分隐藏 */
  text-overflow: ellipsis; /* 超出部分用省略号代替 */
}

.content-analysis {
  padding: 24px;
  background-color: #fff;

  .header-box {
    /* element ui style */
    ::v-deep .el-menu-item {
      color: rgba(37, 38, 50, 0.6);
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      padding: 0 4px;
      margin-right: 24px;
      height: 50px;
      line-height: 50px;
      // line-height: 21px;
      font-weight: 400;
    }

    ::v-deep .el-menu-item.is-active {
      color: #252632;
    }

    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom-color: #fe2c55;
    }
  }

  .content-card-box {
    margin-block: 20px;
    .card {
      background-color: #f7f9fb;
      height: 220px;
      width: 100%;
      padding: 20px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      gap: 16px;
      position: relative;

      &_cover-img {
        position: relative;
        cursor: pointer;
        flex-grow: 0;

        img {
          width: 135px;
          height: 180px;
          border-radius: 8px;
          object-fit: cover;
        }

        .img-play-icon {
          color: rgba(255, 255, 255, 0.6);
          font-size: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &_info {
        width: 50%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #777e87;
        text-align: left;
        font-style: normal;
        text-transform: none;

        & > div {
          margin-bottom: 24px;
        }

        .info-title {
          font-weight: 400;
          font-size: 16px;
          color: #161823;
        }
      }

      &_more {
        position: absolute;
        right: 20px;
        top: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #594fee;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        .select-icon {
          margin-right: 5px;
        }
      }
    }
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: rgba(37, 38, 50, 0.6);
    font-family: PingFang SC, PingFang SC;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    &_label {
      font-size: 18px;
      color: #252632;
    }
  }

  .drawer-content {
    &-item {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;
      cursor: pointer;

      .item-img {
        width: 67px;
        height: 90px;
        border-radius: 2px;
        object-fit: cover;
      }

      .item-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 80%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777e87;
        text-align: left;
        font-style: normal;
        text-transform: none;

        &_title {
          font-size: 16px;
          color: #252632;

          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2; /* 限制为2行 */
          -webkit-box-orient: vertical; /* 必须设置的属性 */
          overflow: hidden; /* 隐藏超出的部分 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }
      }
    }

    .medio-load {
      display: flex;
      justify-content: center;
      padding: 10px 0 0 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar {
    width: 10px;
    /* 滚动条的宽度 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-thumb {
    background-color: rgba(37, 38, 50, 0.2);
    /* 滚动条的颜色 */
    border-radius: 20px;
    /* 滚动条的圆角半径 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(37, 38, 50, 0.2);
    /* 滚动条的悬停颜色 */
  }

  ::v-deep .el-drawer__body::-webkit-scrollbar-track {
    background-color: #fff;
    /* 滚动条的轨道颜色 */
    border-radius: 20px;
    /* 滚动条轨道的圆角半径 */
  }

  ::v-deep .el-drawer__header,
  ::v-deep .el-drawer__body {
    padding: 24px;
    margin: 0;
  }
}
</style>